<template>
  <div>
    <filter-params
      v-model="computedFilter"
      :readonly="readonly"
      :auto-filter-type="targetDocTy"
    />
  </div>
</template>

<script>
export default {
  components: {
    FilterParams: () => import('../filter-params/filter-params')
  },
  data () {
    return {
      dataFilter: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  computed: {
    computedFilter: {
      get () { return this.dataFilter },
      set (v) {
        this.dataFilter = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { filter: this.dataFilter }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: {
      type: String
    }
  },
  watch: {
    document: {
      handler (v) {
        this.dataFilter = v?.filter ? this.lodash.cloneDeep(v.filter) : null
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
